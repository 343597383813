<template>
  <div>
    <Header />
    <div class="Map">
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        @ready="handler"
        @click="getClickInfo"
        :scroll-wheel-zoom="true"
      >
        <div v-for="(item, i) in siteVos" :key="i">
          <bm-marker
            v-if="
              item.deviceStatus == '0' &&
              item.waterLevelAlarmStatus == '0' &&
              item.electricityAlarmStatus == '0'
            "
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{url:require('@/assets/images/bz/zaixian.png'),size: { width: 35, height: 41 }}"
            :zIndex="9"
            @mouseover="openInfoBox(item.lng, item.lat, item.id)"
            @mouseout="closeInfoBox"
            @click="goPush(item.id)"
          >
            <!-- <bm-label
              :content="item.siteName + ' ' + item.currentLevel + 'cm'"
              :zIndex="9"
              :labelStyle="{
                border: '1px solid #0E7771',
                color: '#000000',
                fontSize: '17px',
                textIndent: '20px',
                paddingRight: '10px',
                borderRadius: '8px'
              }"
              :offset="{ width: 20, height: 8 }"
              @mouseover="openInfoBox(item.lng, item.lat, item.id)"
              @mouseout="closeInfoBox"
            /> -->
          </bm-marker>
          <!-- 报警 -->
          <bm-marker
            v-if="
              item.deviceStatus == '0' &&
              (item.waterLevelAlarmStatus == '1' ||
                item.electricityAlarmStatus == '1')
            "
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{url:require('@/assets/images/bz/baojing.png'),size: { width: 35, height: 41 }}"
            :zIndex="9"
            @mouseover="openInfoBox(item.lng, item.lat, item.id)"
            @mouseout="closeInfoBox"
            @click="goPush(item.id)"
          >
            <!-- <bm-label
              :content="item.siteName + ' ' + item.currentLevel + 'cm'"
              :zIndex="9"
              :labelStyle="{
                border: '1px solid #f14c4c',
                color: '#000000',
                fontSize: '17px',
                textIndent: '20px',
                paddingRight: '10px',
                borderRadius: '8px'
              }"
              :offset="{ width: 20, height: 8 }"
              @mouseover="openInfoBox(item.lng, item.lat, item.id)"
              @mouseout="closeInfoBox"
            /> -->
          </bm-marker>
          <bm-marker
            v-if="item.deviceStatus == '1'"
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{url:require('@/assets/images/bz/lixian.png'),size: { width: 35, height: 41 }}"
            :zIndex="9"
            @mouseover="openInfoBox(item.lng, item.lat, item.id)"
            @mouseout="closeInfoBox"
            @click="goPush(item.id)"
          >
            <!-- <bm-label
              :content="item.siteName + ' ' + item.currentLevel + 'cm'"
              :zIndex="9"
              :labelStyle="{
                border: '1px solid #676767',
                color: '#000000',
                fontSize: '17px',
                textIndent: '20px',
                paddingRight: '10px',
                borderRadius: '8px'
              }"
              :offset="{ width: 20, height: 8 }"
              @mouseover="openInfoBox(item.lng, item.lat, item.id)"
              @mouseout="closeInfoBox"
            /> -->
          </bm-marker>
        </div>
        <div
          class="Rain"
          v-for="(items, index) in rainVos"
          :key="'Rain-1' + index"
        >
          <!-- <bm-marker
            v-if="items.rainRunStatus == '0'"
            :position="{ lng: items.lng, lat: items.lat }"
            :icon="{
              url:
                'https://qiniu1.xuruidea.com/27/20210105/rainGreenIcon1609816698958.png',
              size: { width: 38, height: 46 },
            }"
            :zIndex="9"
            @mouseover="
              openRainBox(items.lng, items.lat, items.id, items.rainName)
            "
            @mouseout="closeInfoBox"
          >
            <bm-label
              :content="items.rainName"
              :zIndex="9"
              :labelStyle="{
                border: '1px solid #0E7771',
                color: '#000000',
                fontSize: '17px',
                textIndent: '20px',
                paddingRight: '10px',
                borderRadius: '8px'
              }"
              :offset="{ width: 20, height: 8 }"
              @mouseover="
                openRainBox(items.lng, items.lat, items.id, items.rainName)
              "
              @mouseout="closeInfoBox"
            />
          </bm-marker> -->
          <!-- <bm-marker
            v-if="items.rainRunStatus == '1'"
            :position="{ lng: items.lng, lat: items.lat }"
            :icon="{
              url:
                'https://qiniu1.xuruidea.com/27/20210105/rainGrayIcon1609816696177.png',
              size: { width: 38, height: 46 },
            }"
            :zIndex="9"
            @mouseover="
              openRainBox(items.lng, items.lat, items.id, items.rainName)
            "
            @mouseout="closeInfoBox"
          >
            <bm-label
              :content="items.rainName"
              :zIndex="9"
              :labelStyle="{
                border: '1px solid #676767',
                color: '#000000',
                fontSize: '17px',
                textIndent: '20px',
                paddingRight: '10px',
                borderRadius: '8px'
              }"
              :offset="{ width: 20, height: 8 }"
              @mouseover="
                openRainBox(items.lng, items.lat, items.id, items.rainName)
              "
              @mouseout="closeInfoBox"
            />
          </bm-marker> -->
          <!-- <bm-marker
            v-if="items.rainRunStatus == '2'"
            :position="{ lng: items.lng, lat: items.lat }"
            :icon="{
              url:
                'https://qiniu1.xuruidea.com/27/20210105/rainBlueIcon1609816693319.png',
              size: { width: 38, height: 46 },
            }"
            :zIndex="9"
            @mouseover="
              openRainBox(items.lng, items.lat, items.id, items.rainName)
            "
            @mouseout="closeInfoBox"
          >
            <bm-label
              :content="items.rainName"
              :zIndex="9"
              :labelStyle="{
                border: '1px solid #1c89fb',
                color: '#000000',
                fontSize: '17px',
                textIndent: '20px',
                paddingRight: '10px',
                borderRadius: '8px'
              }"
              :offset="{ width: 20, height: 8 }"
              @mouseover="
                openRainBox(items.lng, items.lat, items.id, items.rainName)
              "
              @mouseout="closeInfoBox"
            />
          </bm-marker> -->
        </div>
        <!-- 渠道流量计  -->
        <div
          v-for="(item, index) in ditchFlowMeters"
          :key="'ditchFlowMeters' + index"
        >
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/llj.png'),
              size: { width: 38, height: 46 },
            }"
            :zIndex="9"
            @mouseover="openDitchFlowMeterBox(item.lng, item.lat, item.meterId)"
            @mouseout="closeDitchFlowMeterBox"
          >
            <!-- <bm-label
                          :content="item.meterName"
                          :zIndex="9"
                          :labelStyle="{
                          border: '1px solid #0E7771',
                          color: '#000000',
                          fontSize: '17px',
                          textIndent: '20px',
                          paddingRight: '10px',
                          borderRadius: '8px'
                      }"
                          :offset="{ width: 20, height: 8 }"
                          @mouseover="openDitchFlowMeterBox(item.lng, item.lat, item.meterId)"
                          @mouseout="closeDitchFlowMeterBox"
                  /> -->
          </bm-marker>
        </div>
        <!-- 流量计  -->
        <div v-for="(item, index) in flowMeters" :key="'flowMeters' + index">
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/llj.png'),
              size: { width: 38, height: 46 },
            }"
            :zIndex="9"
            @mouseover="openFlowMeterBox(item.lng, item.lat, item.devSn)"
            @mouseout="closeFlowMeterBox"
          >
            <!-- <bm-label
                          :content="item.name"
                          :zIndex="9"
                          :labelStyle="{
                          border: '1px solid #0E7771',
                          color: '#000000',
                          fontSize: '17px',
                          textIndent: '20px',
                          paddingRight: '10px',
                          borderRadius: '8px'
                      }"
                          :offset="{ width: 20, height: 8 }"
                          @mouseover="openFlowMeterBox(item.lng, item.lat, item.devSn)"
                          @mouseout="closeFlowMeterBox"
                  /> -->
          </bm-marker>
        </div>
        <!-- 泵站  -->
        <div
          v-for="(item, index) in pumpStations"
          :key="'pumpStations' + index"
        >
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/bz.png'),
              size: { width: 38, height: 46 },
            }"
            :zIndex="9"
            @mouseover="openPumpMeterBox(item.lng, item.lat, item.id)"
            @mouseout="closePumpBox"
            @click="toPumpStationPush(item.id, item.staId)"
          >
            <!-- <bm-label
                          :content="item.staName"
                          :zIndex="9"
                          :labelStyle="{
                          border: '1px solid #0E7771',
                          color: '#000000',
                          fontSize: '17px',
                          textIndent: '20px',
                          paddingRight: '10px',
                          borderRadius: '8px'
                      }"
                          :offset="{ width: 20, height: 8 }"
                          @mouseover="openPumpMeterBox(item.lng, item.lat, item.id)"
                          @mouseout="closePumpBox"
                  /> -->
          </bm-marker>
        </div>
      </baidu-map>

      <!--  地图搜索  -->
      <div class="siteSearch">
        <cascader
          :rainVos="rainVos"
          :siteVos="siteVos"
          :flowMeters="flowMeters"
          :ditchFlowMeters="ditchFlowMeters"
          :pumpStations="pumpStations"
          @data="toLoaction"
        />
      </div>
      <div class="rainStatus Card-b-c">
        <!--        <div class="title">-->
        <!--          <i class="line"></i>-->
        <!--          <div class="title-text font-s-20 font-w-b">雨情状态 :</div>-->
        <!--        </div>-->
        <!--        <ul class="flex flex-j-s-a ul-active">-->
        <!--          <li class="flex">-->
        <!--            <span class="circle-green"></span>-->
        <!--            <div class="text"><p>正常</p></div>-->
        <!--            <div class="numberText">-->
        <!--              <p>{{ normalRainCount }}</p>-->
        <!--            </div>-->
        <!--          </li>-->
        <!--          <li class="flex">-->
        <!--            <span class="circle-blue"></span>-->
        <!--            <div class="text"><p>下雨</p></div>-->
        <!--            <div class="numberText">-->
        <!--              <p>{{ warningRainCount }}</p>-->
        <!--            </div>-->
        <!--          </li>-->
        <!--          <li class="flex">-->
        <!--            <span class="circle-gray"></span>-->
        <!--            <div class="text"><p>离线</p></div>-->
        <!--            <div class="numberText">-->
        <!--              <p>{{ offlineRainCount }}</p>-->
        <!--            </div>-->
        <!--          </li>-->
        <!--        </ul>-->
        <!-- 闸门/橡胶坝状态 -->
        <!-- <div class="title">
          <i class="line"></i>
          <div class="title-text font-s-20 font-w-b">状态：</div>
        </div>
        <ul class="flex flex-j-s-a abul">
          <li class="flex">
            <span class="circle-green"></span>
            <div class="text"><p>正常</p></div>
            <div class="numberText">
              <p>{{ normalSiteCount }}</p>
            </div>
          </li>
          <li class="flex">
          <span class="circle-red"></span>
          <div class="text"><p>报警</p></div>
          <div class="numberText">
            <p>{{ warningSiteCount }}</p>
          </div>
        </li>
          <li class="flex">
            <span class="circle-gray"></span>
            <div class="text"><p>离线</p></div>
            <div class="numberText">
              <p>{{ offlineSiteCount }}</p>
            </div>
          </li>
        </ul> -->
        <!-- <div class="title">
              <i class="line"></i>
              <div class="title-text font-s-20 font-w-b">流量计/泵站状态 :</div>
          </div>
          <div class="indexLlj">
              <ul class="indexLlj_list">
                  <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/lljsmall.png" alt=""></a>流量计<a class="a-display">{{flowMeterCount}}</a></li>
                  <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/qdlljsmall.png" alt=""></a>渠道流量计<a class="a-display">{{ditchFlowMeterCount}}</a></li>
                  <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/bzOfflineSmall.png" alt=""></a>泵站离线：23</li>
                  <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/bzOnlineSmall.png" alt=""></a>泵站<a class="a-display">{{pumpStationCount}}</a></li>
              </ul>
          </div> -->
      </div>
      <!-- <div class="map-data-tip" ref="mapData">
      <div class="tip-type">
        <span class="green"></span>
        <span >正常</span>
      </div>
      <div class="tip-type">
        <span class="red"></span>
        <span >报警</span>
      </div>
      <div class="tip-type">
        <span class="gray"></span>
        <span >离线</span>
      </div>
    </div> -->
    <div class="map-data-tip" ref="mapData">
      <div class="typeOne">
        <span >状态:</span>
      </div>
     <div class="typeTwo">
        <div class="tip-type">
          <span >正常</span>
           <span class="green"></span>
        </div>
        <div class="tip-type">
          <span >离线</span>
           <span class="gray"></span>
        </div>
        <div class="tip-type">
          <span >报警</span>
           <span class="red"></span>
        </div>
     </div>
    </div>
    </div>
    <!-- 底部区域 -->
    <div class="footer flex flex-j-c">
      <div class="gif-top">
        <img src="../../assets/images/hecheng-3.gif" />
      </div>
      <ul class="flex boxul">
        <router-link to="/index" v-if="$store.getters.isNavItems('首页')">
          <div>
            <li class="home-pie pic-box"></li>
            <p>首页</p>
          </div>
        </router-link>
        <router-link
              :to="{ path: `/dataV` }"
          >
              <div>
                  <li class="data-v pic-box"></li>
                  <p>大数据</p>
              </div>
          </router-link>
        <router-link
          v-if="$store.getters.isNavItems('泵站管理')"
          :to="{ path: `/main/pumpStation/${this.bzid}/1-1` }"
        >
          <div>
            <li class="pump-pie pic-box"></li>
            <p>泵站管理</p>
          </div>
        </router-link>
        <router-link
          v-if="$store.getters.isNavItems('拦河闸管理')"
          :to="{ path: `/main/StationManage/${this.siteId}` }"
        >
          <div>
            <li class="control-pie pic-box"></li>
            <p>拦河闸管理</p>
          </div>
        </router-link>
        <!-- <router-link
          :to="{ path: `/main/WaterLevel/${this.siteId}` }"
          v-if="$store.getters.isNavItems('水位监测')"
        >
          <div>
            <li class="waterLevel-pie pic-box"></li>
            <p>水位监测</p>
          </div>
        </router-link> -->
        <router-link
          to="/main/RainCondition/RainFall"
          v-if="$store.getters.isNavItems('雨情遥测')"
        >
          <div>
            <li class="telemetering-pie pic-box"></li>
            <p>雨情遥测</p>
          </div>
        </router-link>
        <router-link
         :to="{ path: `/main/flowMeter` }"
          v-if="$store.getters.isNavItems('测流点管理')"
        >
          <div>
            <li class="rationWater-pie pic-box"></li>
            <p>测流点管理</p>
          </div>
        </router-link>
        <!-- <router-link
          :to="{ path: `/main/VideoAll/${this.siteId}` }"
          v-if="$store.getters.isNavItems('监控总览')"
        >
          <div>
            <li class="monitoring-pie pic-box"></li>
            <p>监控总览</p>
          </div>
        </router-link> -->
        <router-link
          to="/main/Capture"
          v-if="$store.getters.isNavItems('非法抓拍')"
        >
          <div>
            <li class="capture-pie pic-box"></li>
            <p>非法抓拍</p>
          </div>
        </router-link>
        <router-link
          to="/main/SearchManage/FlowStatistics"
          v-if="$store.getters.isNavItems('查询管理')"
        >
          <div>
            <li class="equipment-pie pic-box"></li>
            <p>查询管理</p>
          </div>
        </router-link>
        <!-- <router-link
          to="/main/FacilityBack"
          v-if="$store.getters.isNavItems('设备追回')"
        >
          <div>
            <li class="trace-pie pic-box"></li>
            <p>设备追回</p>
          </div>
        </router-link>
        <router-link
          to="/main/AlarmLog"
          v-if="$store.getters.isNavItems('报警记录')"
        >
          <div>
            <li class="alarmRecord-pie pic-box"></li>
            <p class="clock">报警记录</p>
          </div>
        </router-link> -->
      </ul>
    </div>
    <div class="Police">
      <!-- <Police v-if="totalsimp !== 0" /> -->
    </div>
    <!-- <div class="timeOver">刷新倒计时：{{ time }}秒</div> -->
  </div>
</template>

<script>
import Header from "../../components/Header";
import Police from "../index/components/Table/components/Police";
import {
  getAllSiteList,
  getSiteByIdList,
  getRainById,
  RecordList,
  getDitchFlowMeter,
  getFlowMeter,
  getPumpStation,
} from "../../api/index";
import { getSitesList, getPumpStationMain } from "../../api/main.js";

// import Popup from "./components/popup";
import cascader from "./components/cascader";
import { llj, qdllj, bz } from "@/assets/js/html";

export default {
  inject: ["reload"],
  components: {
    Police,
    Header,
    cascader,
  },
  data() {
    return {
      infoBox: null,
      lastInfoBox: null,
      isMains: "", // 市电或者太阳能
      siteItemVo: {}, // 获取到的站点信息
      gateVo: {}, // 闸门信息
      siteVos: [], // 站点列表
      rainVos: [], // 雨情列表
      ditchFlowMeters: [], // 渠道流量计
      ditchFlowMeterCount: "0",
      flowMeters: [], // 流量计
      flowMeterCount: "0",
      pumpStations: [], // 泵站
      pumpStationCount: "0",
      center: {
        lng: 117.57974754075504,
        lat: 37.60327267762413,
      },
      zoom: 12,
      totalsimp: "",
      // 顺序 雨情 站点 正常 掉线 报警
      normalRainCount: "",
      normalSiteCount: "",
      offlineRainCount: "",
      offlineSiteCount: "",
      warningRainCount: "",
      warningSiteCount: "",
      rainfallTime: "", // 降雨时长
      rainfall: "", // 降雨量
      siteId: "", // 站点id
      searchSite: "", // 站点搜索
      siteVosAndRainVos: [],
      loadingSite: true,
      loadingText: "正在加载",
      returnSearch: false,
      returnSiteVos: [],
      bzid: "",
      staId: "",
      urlBai: {},
    };
  },
  watch: {
    "$store.state.wsRainData"(e) {
      this.normalRainCount = e.normalRainCount;
      this.normalSiteCount = e.normalSiteCount;
      this.offlineRainCount = e.offlineRainCount;
      this.offlineSiteCount = e.offlineSiteCount;
      this.warningRainCount = e.warningRainCount;
      this.warningSiteCount = e.warningSiteCount;
      // this.ditchFlowMeterCount = e.ditchFlowMeterCount
      // this.flowMeterCount = e.flowMeterCount
      // this.pumpStationCount = e.pumpStationCount
    },
  },
  created() {
    this.$store.commit("getNavItems"); // 获取菜单列表
    if (!this.$ws.readyState()) {
      this.$ws.create();
    }
    let query = window.location.href;
    let vars = query.split("?");
    let a = vars[1].split("&");
    this.urlBai = { lng: a[0].split("=")[1], lat: a[1].split("=")[1] };
  },
  mounted() {
    getAllSiteList().then((res) => {
      // console.log("getAllSiteList", res);
      this.loadingSite = true;
      // this.center = res.data.siteVos[0]
      this.siteVos = res.data.siteVos;
      // console.log(this.siteVos);
      this.rainVos = res.data.rainVos;
      this.ditchFlowMeters = res.data.ditchFlowMeters.map((item) => {
        return { ...item, lng: item.lon };
      });
      this.ditchFlowMeterCount = res.data.ditchFlowMeterCount;
      this.flowMeters = res.data.flowMeters.map((item) => {
        return { ...item, lng: item.lon };
      });
      this.flowMeterCount = res.data.flowMeterCount;
      this.pumpStations = res.data.pumpStations.map((item) => {
        return { ...item, lng: item.lon };
      });
      this.pumpStationCount = res.data.pumpStationCount;
      this.normalRainCount = res.data.normalRainCount;
      this.normalSiteCount = res.data.normalSiteCount;
      this.offlineRainCount = res.data.offlineRainCount;
      this.offlineSiteCount = res.data.offlineSiteCount;
      this.warningRainCount = res.data.warningRainCount;
      this.warningSiteCount = res.data.warningSiteCount;
      const rainData = res.data.rainVos.map((item) => {
        return {
          siteName: item.rainName,
        };
      });
      this.siteVosAndRainVos = res.data.siteVos.concat(rainData);
      setTimeout(() => {
        this.loadingSite = false;
      }, 1000);
    });
    getSitesList().then((res) => {
      this.siteId = String(res.data[0].id);
    });
    getPumpStationMain().then((res) => {
      // console.log(res);
      const { id, staId } = res.data[0];
      this.bzid = id;
      this.staId = staId;
    });
    RecordList().then((res) => {
      this.totalsimp =
        res.data.noDisposeLists.length + res.data.disposeLists.length;
    });
  },
  methods: {
    goPush(id) {
      this.$router.push({ path: `/main/StationManage/${id}` });
    },
    toPumpStationPush(id, staId) {
      this.$router.push({ path: `/main/pumpStation/${id}/${staId}` });
    },
    // 打开信息窗口
    openInfoBox(lng, lat, id) {
      // 鼠标移入获取接口数据
      getSiteByIdList({ id: id }).then((res) => {
        this.isMains = res.data.isMains;
        this.siteItemVo = res.data.siteItemVo;
        this.gateVo = res.data.gateVo;
        // <div class="rainBox_title_img">
        //             <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg" alt="" />
        //           </div>
        const html = `<div class="rain">
    <div class="rainBox">
      <div style="margin:0 12px">
        <!-- 头部区域 -->
        <div class="rainBox_title flex flex-a-c ">
          <div class="rainBox_title_num ">
            <span style="font-size:12px">${this.siteItemVo.updateTime}</span>
          </div>
          <div
            class="rainBox_title_text  font-w-b"
            style="color:#333333;font-size:14px"
          >
           ${this.siteItemVo.siteName}

          </div>

        </div>
        <div class="polyin"></div>
        <!-- 闸门信息 -->
        <div class="gate">
          <div class="gate_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">闸门信息</p>
          </div>
          <div class="gate_text">
            <ul class="flex ul_one">
              <li>
                <div class=" font-w-b t-x" style="color:#11B36C;font-size:16px">
                 ${this.gateVo.gateHeight}
                </div>
              </li>
              <li>
                <div class=" font-w-b t-x" style="color:#333333;font-size:16px">
               ${this.gateVo.gateHeightLimit}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" t-x font-s-12" style="color:#11B36C;font-weight: bold;">
                  闸门高度(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#666">
                  闸门限高(cm)
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="polyin"></div>
        <!-- 水文信息 -->
        <div class="waterMes">
          <div class="waterMes_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">水文信息</p>
          </div>
          <div class="waterMes_text">
            <ul class="flex ul_one">
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#11B36C;font-size:16px"
                >
                 ${this.siteItemVo.gateLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#FB4D45;font-size:16px"
                >
                 ${this.siteItemVo.warningLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.traffic}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.velocity}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" font-s-12 " style="color:#11B36C;margin-left:4px;font-weight: bold;">
                  当前水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#FB4D45;font-weight: bold;">
                  警戒水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  瞬时流量(m³/h)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  流速(m/s)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <!-- 气象信息 -->
        <div class="mete">
          <div class="mete_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">气象信息</p>
          </div>
          <div class="mete_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  风速：
                </div>
                <div class="font-w-b font-s-12" style="color:#333">
                 ${this.siteItemVo.direction}风 ${this.siteItemVo.speed}
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  温度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.atmosphericTemperature}(℃)
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class=" t-x font-s-12" style="color:#666;margin-left:4px">
                  湿度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                ${this.siteItemVo.atmosphericHumidity}(RH)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <div class="solar">
          <div class="solar_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">太阳能信息</p>
          </div>
          <div class="solar_text">
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px;">
                  光伏电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.pvVoltage}(V)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                  光伏电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.pvCurrent}(A)
                </div>
              </li>
              <li class="flex">
                <div class="font-s-12" style="color:#666">
                  负载电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.loadCurrent}(A)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  电池温度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.batteryTemperature}(℃)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                  电池电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.batteryVoltage}(V)
                </div>
              </li>
              <li class="flex">
                <div class=" font-s-12" style="color:#666">
                  剩余电量：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.soc}(%)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:50%">
                <div class=" font-s-12" style="color:#666;margin-left:4px">
                  日发电量：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.dailyOutput}(KWH)
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>`;
        //  <div class="rainBox_title_img">
        //             <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg" alt="" />
        //           </div>
        const html1 = ` <div class="rain">
    <div class="rainBox">
      <div style="margin:0 12px">
        <!-- 头部区域 -->
        <div class="rainBox_title flex flex-a-c ">
          <div class="rainBox_title_num ">
            <span style="font-size:12px">${this.siteItemVo.updateTime}</span>
          </div>
          <div
            class="rainBox_title_text  font-w-b"
            style="color:#333333;font-size:14px"
          >
           ${this.siteItemVo.siteName}

          </div>

        </div>
        <div class="polyin"></div>
        <!-- 闸门信息 -->
        <div class="gate">
          <div class="gate_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">闸门信息</p>
          </div>
          <div class="gate_text">
            <ul class="flex ul_one">
              <li>
                <div class=" font-w-b t-x" style="color:#11B36C;font-size:16px">
                 ${this.gateVo.gateHeight}
                </div>
              </li>
              <li>
                <div class=" font-w-b t-x" style="color:#333333;font-size:16px">
               ${this.gateVo.gateHeightLimit}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" t-x font-s-12" style="color:#11B36C;font-weight: bold;">
                  闸门高度(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#666">
                  闸门限高(cm)
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="polyin"></div>
        <!-- 水文信息 -->
        <div class="waterMes">
          <div class="waterMes_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">水文信息</p>
          </div>
          <div class="waterMes_text">
            <ul class="flex ul_one">
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#11B36C;font-size:16px"
                >
                 ${this.siteItemVo.gateLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#FB4D45;font-size:16px"
                >
                 ${this.siteItemVo.warningLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.traffic}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.velocity}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" font-s-12" style="color:#11B36C;margin-left:4px;font-weight: bold;">
                  当前水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#FB4D45;font-weight: bold;">
                  警戒水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  流量(m³/h)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  流速(m/s)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <!-- 气象信息 -->
        <div class="mete">
          <div class="mete_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">气象信息</p>
          </div>
          <div class="mete_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  风速：
                </div>
                <div class="font-w-b font-s-12" style="color:#333">
                 ${this.siteItemVo.direction}风 ${this.siteItemVo.speed}
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  温度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.atmosphericTemperature}(℃)
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class=" t-x font-s-12" style="color:#666;margin-left:4px">
                  湿度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                ${this.siteItemVo.atmosphericHumidity}(RH)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <div class="solar">
          <div class="solar_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">太阳能信息</p>
          </div>
          <div class="solar_text">
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px;">
                  A相电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
               ${this.siteItemVo.aCurrent}(A)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                 B相电流
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
               ${this.siteItemVo.bCurrent}(A)
                </div>
              </li>
              <li class="flex">
                <div class="font-s-12" style="color:#666">
                 C相电流
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                ${this.siteItemVo.cCurrent}(A)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                 D相电流
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.dCurrent}(A)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                  A相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.aVoltage}(V)
                </div>
              </li>
              <li class="flex">
                <div class=" font-s-12" style="color:#666">
                 B相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.bVoltage}(V)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:50%">
                <div class=" font-s-12" style="color:#666;margin-left:4px">
                  C相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.cVoltage}(V)
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>`;

        // 流量计
        const lljHtml = llj({ name: "sss" });
        // 渠道流量计
        const qdlljHtml = qdllj({ name: "渠道流量计" });
        // 泵站
        const bzHtml = bz({ name: "泵站" });

        const opts = {
          boxStyle: {
            width: "400px",
            height: "350px",
          },
          closeIconMargin: "calc(50/1080*100vh) calc(20/1920*100vw) 0 0",
          closeIconUrl: require("@/assets/images/index/guanbi.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        if (this.isMains == 1) {
          this.infoBox = new BMapLib.InfoBox(this.map, html1, opts);
        } else {
          this.infoBox = new BMapLib.InfoBox(this.map, html, opts);
        }
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        document.querySelector(".rainStatus").style.opacity = 0; // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName(
          "rainBox_title_img"
        );
        var that = this;
        guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        function showMsg() {
          // alert("事件监听");
          that.infoBox.close();
          document.querySelector(".rainStatus").style.opacity = 0.8;
        }
      });
    },
    closeInfoBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".rainStatus").style.opacity = 0.8; // 显示雨情控件
      }
    },
    // 渠道流量计弹框
    openDitchFlowMeterBox(lng, lat, id) {
      getDitchFlowMeter({ meterId: id }).then((res) => {
        // 渠道流量计
        const qdlljHtml = qdllj(res.data);
        const opts = {
          boxStyle: {
            width: "420px",
            height: "360px",
          },
          closeIconMargin: "calc(50/1080*100vh) calc(20/1920*100vw) 0 0",
          closeIconUrl: require("@/assets/images/index/guanbi.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        this.infoBox = new BMapLib.InfoBox(this.map, qdlljHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        document.querySelector(".rainStatus").style.opacity = 0; // 隐藏雨情控件

        var guanbiBtn = window.document.getElementsByClassName("title_img");
        var that = this;
        guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        function showMsg() {
          // alert("事件监听");
          that.infoBox.close();
          document.querySelector(".rainStatus").style.opacity = 0.8;
        }
      });
    },

    closeDitchFlowMeterBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".rainStatus").style.opacity = 0.8; // 显示雨情控件
      }
    },

    // 流量计弹框
    openFlowMeterBox(lng, lat, id) {
      getFlowMeter({ devSn: id }).then((res) => {
        // console.log(res);
        // 渠道流量计
        const lljHtml = llj(res.data);
        const opts = {
          boxStyle: {
            width: "420px",
            height: "360px",
          },
          closeIconMargin: "calc(50/1080*100vh) calc(20/1920*100vw) 0 0",
          closeIconUrl: require("@/assets/images/index/guanbi.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        this.infoBox = new BMapLib.InfoBox(this.map, lljHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        document.querySelector(".rainStatus").style.opacity = 0; // 隐藏雨情控件

        var guanbiBtn = window.document.getElementsByClassName("title_img");
        var that = this;
        guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        function showMsg() {
          // alert("事件监听");
          that.infoBox.close();
          document.querySelector(".rainStatus").style.opacity = 0.8;
        }
      });
    },

    closeFlowMeterBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".rainStatus").style.opacity = 0.8; // 显示雨情控件
      }
    },

    // 泵站弹框
    openPumpMeterBox(lng, lat, id) {
      getPumpStation({ id: id }).then((res) => {
        console.log(res);
        // 渠道流量计
        const bzHtml = bz(res.data);
        const opts = {
          boxStyle: {
            width: "420px",
            height: "310px",
          },
          closeIconMargin: "calc(50/1080*100vh) calc(20/1920*100vw) 0 0",
          closeIconUrl: require("@/assets/images/index/guanbi.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        this.infoBox = new BMapLib.InfoBox(this.map, bzHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        document.querySelector(".rainStatus").style.opacity = 0; // 隐藏雨情控件

        var guanbiBtn = window.document.getElementsByClassName("title_img");
        var that = this;
        guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        function showMsg() {
          // alert("事件监听");
          that.infoBox.close();
          document.querySelector(".rainStatus").style.opacity = 0.8;
        }
      });
    },

    closePumpBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".rainStatus").style.opacity = 0.8; // 显示雨情控件
      }
    },
    // 雨情弹框
    openRainBox(lng, lat, id, rainName) {
      console.log(rainName);
      getRainById({ id, id }).then((res) => {
        this.rainfallTime = res.data.rainfallTime;
        this.rainfall = res.data.rainfall;

        const RainHtml = `
      <div class="RainBox">
      <button>${rainName}</button>
      <div><span>降雨量：</span><span>${this.rainfall}mm</span></div>
      <div class="timer"><span>降雨时长：</span><span>${this.rainfallTime}</span></div>
      </div>
      `;

        const opts = {
          boxStyle: {
            width: "calc(242/1920*100vw)",
            height: "calc(120/1080*100vh)",
          },
          closeIconMargin: "calc(15/1080*100vh) calc(5/1920*100vw) 0 0",
          closeIconUrl: require("../../assets/images/index/rain.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };
        this.infoBox = new BMapLib.InfoBox(this.map, RainHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
      });
    },
    getBoundary(map, district) {
      var bdary = new BMap.Boundary();
      bdary.get(district, function (rs) {
        // 获取行政区域
        // map.clearOverlays(); //清除地图覆盖物
        var count = rs.boundaries.length; // 行政区域的点有多少个
        if (count === 0) {
          /* alert('未能获取当前输入行政区域'); */
          return;
        }
        const EN_JW = "180, 90;";
        const NW_JW = "-180,  90;";
        const WS_JW = "-180, -90;";
        const SE_JW = "180, -90;";
        // 东南西北四个角添加一个覆盖物
        const ply1 = new BMap.Polygon(
          rs.boundaries[0] + SE_JW + SE_JW + WS_JW + NW_JW + EN_JW + SE_JW,
          {
            strokeColor: "none",
            fillColor: "#fff",
            fillOpacity: 1,
            strokeOpacity: 1,
          }
        );
        map.addOverlay(ply1);
        var pointArray = [];
        for (var i = 0; i < count; i++) {
          var ply = new BMap.Polygon(rs.boundaries[i], {
            strokeWeight: 2, // 设置多边形边线线粗
            strokeOpacity: 1, // 设置多边形边线透明度0-1
            StrokeStyle: "dashed", // 设置多边形边线样式为实线或虚线，取值 solid 或 dashed
            strokeColor: "#009944", // 设置多边形边线颜色
            fillColor: "#8BFFCC", // 设置多边形填充颜色
            fillOpacity: 0.13, // 设置多边形填充颜色透明度0-1  注：标红的地放你们可以去掉看一下效果，自己体验一下
          }); // 建立多边形覆盖物
          map.addOverlay(ply); // 添加覆盖物
          // pointArray = pointArray.concat(ply.getPath())
        }
        // map.setViewport(pointArray) // 调整视野
      });
      console.log(this.urlBai);
      if (this.urlBai.lat) {
        console.log("111111");
        setTimeout(() => {
          this.map.centerAndZoom(
            new BMap.Point(this.urlBai.lat, this.urlBai.lng),
            18
          );
        }, 2000);
      }
    },
    handler({ BMap, map }) {
      this.map = map;
      setTimeout(() => {
        this.getBoundary(this.map, "阳信县");
      }, 100);
    },
    infoWindowClose(marker) {
      marker.showFlag = false;
    },
    infoWindowOpen(marker) {
      marker.showFlag = true;
    },
    getClickInfo(e) {
      // console.log(e.point.lng);
      // console.log(e.point.lat);
      // this.center.lng = e.point.lng;
      // this.center.lat = e.point.lat;
    },
    onSearch(e) {
      this.returnSearch = true;
      var value = this.searchSite;
      var item = this.siteVosAndRainVos.filter(
        (item) => item.siteName.indexOf(value.toLowerCase()) !== -1
      );
      console.log(item, this.siteVosAndRainVos);
      if (item.length > 0) {
        this.returnSiteVos = item;
      } else {
        this.returnSiteVos = [];
        this.loadingText = "暂无站点信息";
      }
    },
    toLoaction(item) {
      if (item === "") {
        this.reload();
      } else {
        const new_point = new BMap.Point(item.lng, item.lat);
        const maker = new BMap.Marker(new_point);
        this.map.centerAndZoom(new BMap.Point(item.lng, item.lat), 18);
        // this.map.enableScrollWheelZoom(true)
        // this.map.addOverlay(maker)
        // this.map.panTo(new_point)
      }
    },
  },
};
</script>
<style>
.el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-icon-circle-close::before {
  content: "\e78d" !important;
}
.el-input__inner::placeholder {
  font-weight: normal !important;
}
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #2c599b;
}
</style>
<style lang="scss" scoped>
// 状态
.map-data-tip {
  border: 1px solid #0196FF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  width: 130px;
  max-height: 300px;
  height: 100px;
  padding: 10px;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 100;
  background: rgba(255, 255, 255,0.7);
  transform: translateX(-50%);
  display: flex;
  color: #434343;
  //  flex-direction: column;
  // justify-content: space-around;

}
.typeOne{
  margin-top: 8px;
  padding-right: 15px;
  span{
    font-size: 15px;
    font-weight: bold;
  }
}
.tip-type {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    //margin: 5px ;
    padding-right: 10px;
  }
  .gray{
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #676767;
  }
  .red{
   width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #F14D4D;
  }
  .green{
  width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #3D7EF2;
  }
}
.Map {
  width: 100%;
  height: calc(986 / 1080 * 100vh);
  position: relative;
  // 站点搜索
  .siteSearch {
    position: absolute;
    width: 400px;
    top: 20px;
    right: 20px;
    border-radius: 5px;
  }
  // 雨情状态
  .rainStatus {
    position: absolute;
    top: calc(20 / 1920 * 100vw);
    left: calc(20 / 1920 * 100vw);
    width: calc(346 / 1920 * 100vw);
    /*height: calc(180 / 1080 * 100vh);*/
    opacity: 0.8;
  //  border: calc(2 / 1920 * 100vw) solid #0196ff;
    .title {
      margin-top: calc(5 / 1080 * 100vh);
      position: relative;
      height: calc(50 / 1080 * 100vh);
      .line::after {
        content: "";
        width: calc(3 / 1920 * 100vw);
        height: calc(17 / 1080 * 100vh);
        background-color: #3669ab;
        position: absolute;
        left: calc(17 / 1920 * 100vw);
        top: calc(13 / 1080 * 100vh);
      }
      .title-text {
        height: 100%;
        text-indent: calc(34 / 1920 * 100vw);
        line-height: calc(44 / 1080 * 100vh);
        color: #434343;
      }
    }
    .indexLlj {
      padding: 5px 12px;
      margin-bottom: 12px;
      .indexLlj_list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .indexLlj_item {
          display: flex;
          width: 50%;
          align-items: center;
          color: #333333;
          font-weight: 500;
          .img {
            display: block;
            width: 20px;
            height: 24px;
            margin-right: 4px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .a-display {
            display: block;
            margin-left: 5px;
            color: #333;
            font-weight: bold;
            font-size: calc(20 / 1920 * 100vw);
          }
          &:nth-child(3) {
            margin-top: 10px;
          }
          &:nth-child(4) {
            margin-top: 10px;
          }
        }
      }
    }
  }
  ul {
    li {
      position: relative;
      color: #434343;
      .text {
        width: calc(48 / 1920 * 100vw);
        font-size: calc(14 / 1920 * 100vw);
        position: relative;
        left: calc(2 / 1920 * 100vw);
        p {
          position: absolute;

          left: calc(10 / 1920 * 100vw);
          bottom: 0;
        }
      }
      .numberText {
        width: calc(34 / 1920 * 100vw);
        position: relative;
        bottom: calc(1 / 1080 * 100vh);
        p {
          color: #333;
          font-weight: bold;
          font-size: calc(30 / 1920 * 100vw);
          position: relative;
          font-size: calc(20 / 1920 * 100vw);
          // bottom: 0;
          top: 5px;
        }
      }
    }
  }
  .abul {
    position: absolute;
    top: 35px;
    right: -2%;
  }
  .ul-active {
    padding-bottom: 10px;
  }
  .map {
    width: 100%;
    height: 100%;
  }
}
.footer {
  width: 100%;
  height: calc(64 / 1080 * 100vh);
  // background: url("../../assets/images/index/footer.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  //   left: 50%;
  p {
    font-size: calc(16 / 1920 * 100vw);
    font-weight: normal;
    color: #ffffff;
  }
  transition: opacity 800ms;
  &:hover {
    background-image: url("../../assets/images/index/footer.png");
    background-repeat: no-repeat;
    > ul {
      opacity: 1;
    }
    .gif-top {
      opacity: 0;
    }
  }
  .gif-top {
    position: absolute;
    bottom: 0;
  }
  // 首页
  .home-pie {
    background: url("../../assets/images/footerMenu/home.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 大数据
  .data-v {
     background: url("../../assets/images/footerMenu/dataV.png") no-repeat;
     background-size: 90% auto;
     background-position: center;
  }
  // 泵站管理
  .pump-pie {
    background: url("../../assets/images/footerMenu/pump.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 站点管理
  .control-pie {
    background: url("../../assets/images/footerMenu/control.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 测流点管理
  .rationWater-pie {
    background: url("../../assets/images/footerMenu/flowmeter.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 水位监测
  .waterLevel-pie {
    background: url("../../assets/images/footerMenu/waterLevel.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 雨情遥测
  .telemetering-pie {
    background: url("../../assets/images/footerMenu/telemetering.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 监控总览
  .monitoring-pie {
    background: url("../../assets/images/footerMenu/monitoring.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 非法抓拍
  .capture-pie {
    background: url("../../assets/images/footerMenu/capture.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 设备管理
  .equipment-pie {
    background: url("../../assets/images/footerMenu/equipment.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 设备追踪
  .trace-pie {
    background: url("../../assets/images/footerMenu/trace.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 报警记录
  .alarmRecord-pie {
    background: url("../../assets/images/footerMenu/alarmRecord.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  ul {
    margin-left: calc(24 / 1920 * 100vw);
    opacity: 0;
    div {
      text-align: center;
      position: relative;
      bottom: calc(45 / 1080 * 100vh);
      li {
        width: calc(65 / 1920 * 100vw);

        margin: 0 calc(8 / 1920 * 100vw) 0 calc(8 / 1920 * 100vw);
      }
    }
  }
}
.Police {
  position: absolute;
  bottom: calc(20 / 1080 * 100vh);
  right: calc(8 / 1920 * 100vw);
}
::v-deep .rain {
  padding: 3px;
  width: 386px;
  height: 342px;
  background: #217acc;
  box-shadow: 0px 0px 13px 0px rgba(6, 0, 1, 0.27);
  border-radius: 16px;
  .rainBox {
    // margin: 5px;
    width: 386px;
    height: 342px;
    background: #f3f6f8;
    border-radius: 16px;
    .rainBox_title {
      height: 30px;
      .rainBox_title_num {
        width: 150px;
        color: #333;
        font-weight: 400;
      }
      .rainBox_title_text {
        width: 200px;
        color: #217acc;
      }
      .rainBox_title_img {
        // margin-left: 155px;
        width: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .polyin {
      height: 1px;
      background: #666666;
      opacity: 0.1;
      border-radius: 16px;
    }
    // 闸门信息
    .gate {
      height: 65px;
      .gate_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .gate_text {
        .ul_one {
          li {
            width: 50%;
          }
        }
        .ul_two {
          li {
            width: 50%;
          }
        }
      }
    }
    // 水文信息
    .waterMes {
      height: 65px;
      .waterMes_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .waterMes_text {
        margin-top: 5px;
      }
      .ul_one {
        li {
          width: 25%;
        }
      }
      .ul_two {
        li {
          width: 25%;
        }
      }
    }
    // 气象信息
    .mete {
      height: 45px;
      .mete_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .mete_text {
        margin-top: 6px;
        .ul_two {
          li {
            // width: 30%;
            // margin-left: 18px;
          }
        }
      }
    }
    .solar {
      height: 100px;
      .solar_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 2px;
        }
      }
      .solar_text {
        margin-top: 8px;
        .ul_two {
          li {
            height: 25px;
          }
        }
      }
    }
  }
}

::v-deep .RainBox {
  width: calc(250 / 1920 * 100vw);
  height: calc(116 / 1080 * 100vh);
  background: rgba(243, 248, 255, 0.5);
  border: calc(3 / 1920 * 100vw) solid #1f78ec;
  border-radius: 8px;
  button {
    margin: calc(10 / 1080 * 100vh) 0;
    margin-left: calc(12 / 1920 * 100vw);
    width: calc(224 / 1920 * 100vw);
    height: calc(30 / 1080 * 100vh);
    background: #1f78ec;
    border-radius: 8px;
    color: #dbebf9;
    font-size: calc(18 / 1920 * 100vw);
    font-weight: bold;
    line-height: calc(20 / 1080 * 100vh) !important;
  }
  .timer {
    margin-top: calc(3 / 1080 * 100vh);
  }
  span:nth-child(1) {
    margin-left: calc(12 / 1080 * 100vh);
    font-size: calc(16 / 1920 * 100vw);
    color: #666666;
  }
  span:nth-child(2) {
    font-size: calc(16 / 1920 * 100vw);
    font-weight: bold;
    color: #333333;
  }
}
.router-link-active {
  text-decoration: none;
  color: #ffd04b;
}
.boxul {
  a {
    margin-left: calc(35 / 1920 * 100vw);
  }
}
a {
  text-decoration: none;
  color: #fff;
}
</style>
